import React, { useState, PureComponent } from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { FaInfoCircle, FaRegTimesCircle } from 'react-icons/fa'
import './CoffeeType.css'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts'
import { ResponsiveRadar } from '@nivo/radar'

/* Inicio: Modal  */
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { color } from '@mui/system'
import { List } from '@mui/material'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

/* Fin: Modal  */

export default function CoffeeType ({ item }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const lines = item.paragraphs.map((line, index) =>
    <Typography paragraph key={item.id + '_' + index} >
      {line.text}
    </Typography>
  )

  const listData = Array(Object)

  item.evaluate.forEach((note, index) => {
    const temp = { subject: null, A: 0, B: 50, fullMark: 30 }
    temp.subject = note.title
    temp.A = note.value
    listData.push(temp)
  })

  return (
    <div className='cardDiv' id={'productList_' + item.id}>
      <Card key={item.id} sx={{ maxWidth: '50vh', background: 'transparent', boxShadow: 'none' }} >
        <CardMedia
          component='img'
          height='194'
          image={item.image}
          alt={item.title}
          className='imageDiv'
        />
        <CardContent className='contentCard'>
          <Typography variant='body2' color='text.secondary' className='textCard'>
            <CardHeader
              title={item.title}
              // subheader='Valor: '.${item.value}
            />
            <Typography>
              <b>Valor:</b> {item.value}
            </Typography>

            <div className='buttonsDiv' >
              <IconButton aria-label="Ver detalles" onClick={handleOpen} >
                <FaInfoCircle />
              </IconButton>
            </div>

          </Typography>
        </CardContent>
      </Card>

      <Modal
        key ={item.id + 'modal'}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='modalBox'>
          <IconButton
            onClick={handleClose}
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="closeModal"
          >
            <FaRegTimesCircle />
          </IconButton>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            <CardMedia
              component='img'
              height='194'
              // width='50'
              image={item.image}
              alt={item.title}
              className='imageModal'
            />
            {item.title}
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 1 }} className='textModal'>
            <Typography paragraph className='valueModal'>
              <b>Valor: </b> {item.value}
            </Typography>
            {lines}
          </Typography>

          <div className='graphModal'>
            <RadarChart
              cx={300}
              cy={150}
              outerRadius={100}
              width={500}
              height={300}
              data={listData}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis />
              <Radar
                name="Mike"
                dataKey="A"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </div>

          <CardMedia
            component='img'
            image={item.point}
            alt={item.title}
            className='pointModal'
          />
        </Box>
      </Modal>
    </div>
  )
}
