import React, { useState } from 'react'
import './NavbarMenu.css'
import logo from '../logo.png'
import { FaBars, FaTimes, FaCoffee, FaUserAlt, FaInstagram, FaHome } from 'react-icons/fa'

const navigation = [
  { id: 'nav1', idDiv: 'divNav1', name: 'Inicio', href: '#home', current: true, icon: <FaHome /> },
  { id: 'nav2', idDiv: 'divNav2', name: 'Café', href: '#product', current: false, icon: <FaCoffee /> },
  { id: 'nav4', idDiv: 'divNav4', name: 'Contacto', href: '#contact', current: false, icon: <FaUserAlt /> }
]
const itemsNav = navigation.map((item) =>
  <li key={item.idDiv} className={item.current ? 'active' : ''}>
    <a key={item.id} href={item.href}>
      {item.icon}{item.name}
    </a>
  </li>
)

export default function NavbarMenu () {
  const [clicked, setClicked] = useState(false)
  return (
    <nav className='nav'>
      <img src={logo} className='navbar-logo' alt='logo' />

      <div className='navbar-icon' onClick={() => setClicked(!clicked)}>
        {clicked ? <FaTimes /> : <FaBars />}
      </div>

      <ul className={clicked ? 'navbar-menu active' : 'navbar-menu'}>
        {itemsNav}
      </ul>
    </nav>
  )
}
