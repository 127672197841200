import React from 'react'
import './App.css'
import NavbarMenu from './components/NavbarMenu'
import Slider from './components/Slider'
import ImagesItems from './models/images'
import CoffeeType from './components/CoffeeType'
import coffeeTypeItems from './models/coffeeType'
import Footer from './components/Footer'
import Travel from './components/Travel'
import Contact from './components/Contact'

function App () {
  return (
    <div className="App">
      <header>
        <NavbarMenu />
      </header>

      <section id="home">
        <Slider images={ImagesItems} />
      </section>

      <section id="product">
        <h2>Nuestra variedades de café</h2>
        <div id="productList" >
          {coffeeTypeItems.map((item, index) =>
            <CoffeeType key={'listProduct_' + index} item={item} />
          )}
        </div>
      </section>

      <section id="travel">
        <Travel />
      </section>

      <section id="contact">
        <h2>¿Fanático del café?, escribenos</h2>
        <Contact />
      </section>

      <Footer />
    </div>
  )
}

export default App
