import React from 'react'
import './Travel.css'
import travelItems from '../models/travelItems'

export default function Travel () {
  const items = travelItems.map((item, index) =>
    <div key={item.id + '_' + index} className='travelItem'>
        <div key={'icon_' + item.id + '_' + index} className='travelIcon'>
            {item.icon}
        </div>

        <div key={'title_' + item.id + '_' + index} className='travelTitle'>
            {item.title}
        </div>
        <br></br>
        <div key={'text_' + item.id + '_' + index} className='travelText'>
            {item.text}
        </div>
    </div>
  )

  return (
    <div>
        <div>
            <h2 className="travelHeader">
                ¿Cómo funciona?
            </h2>
        </div>

        <div id="travelList" >
            {items}
        </div>
    </div>
  )
}
