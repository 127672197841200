import LogoGeisha from '../images/products/logo-geisha.png'
import LogoBourbon from '../images/products/logo-bourbon.png'
import PointsGeisha from '../images/products/points-geisha.png'
import PointsBourbon from '../images/products/points-bourbon.png'

const types = [
  {
    id: '0',
    title: 'Café Geisha',
    value: '$50.000',
    image: LogoGeisha,
    point: PointsGeisha,
    paragraphs: [
      { text: 'Geisha es una variedad original de café que fue descubierta en los años 30 en las montañas alrededor de un pueblo llamado Geisha, al sureste de Etiopía. Los árboles Geisha crecen alto y se pueden distinguir por sus hermosas hojas alargadas.' },
      { text: 'La calidad de este café se mejora drásticamente cuando se cultiva a alturas extremadamente elevadas.' }
    ],
    evaluate: [
      { title: 'Fragancia/Aroma', value: 8.25 },
      { title: 'Sabor', value: 8 },
      { title: 'Acidez', value: 8 },
      { title: 'Cuerpo', value: 8 },
      { title: 'Sabor de boca', value: 8 },
      { title: 'Balance', value: 8 },
      { title: 'General', value: 8 }
    ]
  },
  {
    id: '1',
    title: 'Bourbon Rosado',
    value: '$10.000',
    image: LogoBourbon,
    point: PointsBourbon,
    paragraphs: [
      { text: 'El café Bourbon Rosado es una mezcla genética entre el Bourbon Rojo y el Amarillo.' },
      { text: 'En este caso, el fruto maduro es de color Rosado.' },
      { text: 'Este varietal es muy buscado por su rareza genética y la dificultad de ser cultivado y cosechado.' }
    ],
    evaluate: [
      { title: 'Fragancia/Aroma', value: 8.5 },
      { title: 'Sabor', value: 8.25 },
      { title: 'Acidez', value: 8 },
      { title: 'Cuerpo', value: 8 },
      { title: 'Sabor de boca', value: 8.25 },
      { title: 'Balance', value: 8 },
      { title: 'General', value: 8 }
    ]
  },
  {
    id: '2',
    title: 'Pronto nueva variedad',
    value: '$9.000',
    image: LogoBourbon,
    point: PointsBourbon,
    paragraphs: [
      { text: 'Próximamente traeremos más variedades.' }
    ],
    evaluate: [
      { title: 'Fragancia/Aroma', value: 8.5 },
      { title: 'Sabor', value: 8.25 },
      { title: 'Acidez', value: 8 },
      { title: 'Cuerpo', value: 8 },
      { title: 'Sabor de boca', value: 8.25 },
      { title: 'Balance', value: 8 },
      { title: 'General', value: 8 }
    ]
  }
]

export default types
