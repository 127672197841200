import React from 'react'
import { FaCoffee, FaTruck, FaAward } from 'react-icons/fa'

const items = [
  {
    id: 'travel_1',
    title: 'CAFÉ EN GRANO BIEN TOSTADO',
    text: 'Seleccionamos y tostamos el café en grano de los mejores caficultores del mundo, siempre frescos de la más reciente cosecha.',
    icon: <FaCoffee />
  },
  {
    id: 'travel_2',
    title: 'DESPACHAMOS A TODO CHILE',
    text: 'Despachamos tu café recién tostado a donde estés, no importa dónde sea, desde Arica a Punta Arenas.',
    icon: <FaTruck />
  },
  {
    id: 'travel_3',
    title: 'GARANTIA SATISFACCIÓN 100%',
    text: 'Tu compra está siempre protegida. Si no te gusta el café, te lo cambiamos o realizamos reembolso por el total de tu pedido.',
    icon: <FaAward />
  }
]

export default items
