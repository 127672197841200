import React, { useRef } from 'react'
import useSlider from '../hooks/useSlider'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import './Slider.css'

const Slider = ({ images }) => {
  const slideImage = useRef(null)
  const slideText = useRef(null)
  const { goToPreviousSlide, goToNextSlide } = useSlider(
    slideImage,
    slideText,
    images
  )

  return (
    <div className='slider' ref={slideImage}>
      <div className='slider--content'>
        <button onClick={goToPreviousSlide} className='slider__btn-left'>
          <FaAngleLeft />
        </button>
        <h1 className='feature--title'>Café Bacano</h1>
        <p ref={slideText} className='feature--text'></p>
        <div className='slider--feature'>
        </div>
        <button onClick={goToNextSlide} className='slider__btn-right'>
          <FaAngleRight />
        </button>
      </div>
    </div>
  )
}

export default Slider
