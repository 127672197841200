import React from 'react'
import './Footer.css'
import logo from '../logo.png'
import { FaInstagram, FaFacebookF, FaWhatsapp } from 'react-icons/fa'

export default function Footer () {
  return (
    <footer id="footer">
      <img src={logo} id="logoFooter" alt="logo" />

      <div id="rrssFooter">
        <a href="https://www.instagram.com/cafebacano.cl" target="_blank" rel='noreferrer noopener'>
          <FaInstagram />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100076401451769" target="_blank" rel='noreferrer noopener'>
          <FaFacebookF />
        </a>
        <a href="https://wa.me/56935310943?text=Hola,%20vi%20tu%20contacto%20en%20el%20sitio%20web%20y%20quisiera%20conocer%20más%20sobre%20tus%20productos" target="_blank" rel='noreferrer noopener'>
          <FaWhatsapp />
        </a>
      </div>
      <div id="copyright">
        Café Bacano® 2022. Todos los derechos reservados
      </div>
    </footer>
  )
}
