import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import image from '../images/contact/cup.png'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import { FaUserAlt, FaEnvelope, FaPaperPlane } from 'react-icons/fa'
import './Contact.css'
import ReCAPTCHA from 'react-google-recaptcha'

export default function Contact () {
  const form = useRef()
  const refCaptcha = useRef()

  const [toSend, setToSend] = useState({
    name: '',
    email: '',
    text: ''
  })

  const token = '6Lf1EDwiAAAAAICpU5VzMydBK-KEcZU22Nay7tt2'

  const sendEmail = (e) => {
    console.log(e)
    const params = {
      ...toSend,
      'g-recaptcha-response': token
    }

    e.preventDefault()

    emailjs.sendForm('service_testpersonal', 'template_6r0c4e9', form.current, 'y2PefHEVSVIabnAhe')
      .then((result) => {
        console.log(result.text)
      }, (error) => {
        console.log(error.text)
      })
  }

  return (
    <div id='contactDiv'>
      <div id='contactImg'>
        <img src={image} />
      </div>

      <form id='contactForm' ref={form} onSubmit={sendEmail}>
        <TextField
          required
          id='nameContact'
          name='nameContact'
          label='Nombre'
          defaultValue=''
          placeholder='Ingrese su nombre'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FaUserAlt />
              </InputAdornment>
            )
          }}
        />
        <TextField
          required
          id='emailContact'
          name='emailContact'
          label='E-mail'
          defaultValue=''
          placeholder='Ingrese su e-mail'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FaEnvelope />
              </InputAdornment>
            )
          }}
        />
        <TextField
          required
          id='textContact'
          name='textContact'
          label='Mensaje'
          defaultValue=''
          placeholder='Ingrese su mensaje'
          multiline
          rows={4}
        />

        <ReCAPTCHA
          ref={refCaptcha}
          sitekey={'y2PefHEVSVIabnAhe'}
        />

        <Button variant='contained' value="Send" type='submit' endIcon={<FaPaperPlane />}>
          Enviar
        </Button>
      </form>
    </div>
  )
}
