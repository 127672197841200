import Slide1 from '../images/slide-1.jpg'
import Slide2 from '../images/slide-2.jpg'
import SlideA from '../images/slide-a.png'
import SlideB from '../images/slide-b.png'

export default [
  {
    src: `${Slide1}`,
    text: 'Grano'
  },
  {
    src: `${Slide2}`,
    text: 'Promoción'
  },
  {
    src: `${SlideA}`,
    text: ''
  },
  {
    src: `${SlideB}`,
    text: ''
  }
]
